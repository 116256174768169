import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    name: "landing",
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "landing" */ "@index/views/landing/landing-wrapper.vue"
      ),
    beforeEnter: (to, from, next) => {
      const query = to.query || {};
      const isFromPrimary = (from || {}).name === "Primary";

      if(localStorage && (query.utm_source || isFromPrimary)){
        localStorage.setItem("utm_source", query.utm_source || "");
        next();
        return;
      }

      next({ path: "/primary2" });
    },
    meta: {
      layout: "layout-full-screen",
      footerVariant: "landing",
    },
  },
  {
    path: "/identity",
    component: () =>
      import(
        /* webpackChunkName: "form" */ "@index/views/identity/identity.vue"
      ),
  },
  {
    path: "/lite/:step?",
    component: () =>
      import(/* webpackChunkName: "form" */ "@index/views/form/form.vue"),
    meta: {
      layout: "layout-width-932",
      workflow: "security",
    },
  },
  {
    path: "/primary/:step?",
    component: () =>
      import(/* webpackChunkName: "form" */ "@index/views/form/form.vue"),
    meta: {
      layout: "layout-width-932",
      footerVariant: "Primary1",
      workflow: "direct",
    },
  },
  {
    path: "/primary2/:step?",
    component: () =>
      import(/* webpackChunkName: "form" */ "@index/views/form/form.vue"),
    meta: {
      layout: "layout-width-932",
      workflow: "directWithCustomCheckboxes",
    },
  },
  {
    path: "/final",
    name: "final",
    component: () =>
      import(
        /* webpackChunkName: "final" */ "@index/views/final/final.vue"
      ),
  },
  {
    name: "showcase-blue",
    path: "/showcase-blue",
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "@frontend/fin-recurrent-main/showcases/showcase-blue/showcase.vue"
      ),
    props: { getInfo: 73 },
    meta: {
      layout: "layout-full-screen",
    },
  },
  {
    name: "showcase-yellow-m1",
    path: "/showcase-yellow-m1",
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "@frontend/fin-recurrent-main/showcases/showcase-yellow-m1/showcase.vue"
      ),
    meta: {
      layout: "layout-full-screen",
    },
  },
  {
    name: "showcase-yellow-m2",
    path: "/showcase-yellow-m2",
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "@frontend/fin-recurrent-main/showcases/showcase-yellow-m2/showcase.vue"
      ),
    meta: {
      layout: "layout-full-screen",
    },
  },
  {
    name: "showcase-combacker",
    path: "/showcase-combacker",
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "@frontend/fin-recurrent-main/showcases/combacker.vue"
      ),
    meta: {
      layout: "layout-blank",
    },
  },
  {
    name: "showcase-gifts",
    path: "/showcase-gifts",
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "@frontend/fin-recurrent-main/showcases/showcase-gifts/showcase.vue"
      ),
    props: { getInfo: 105 },
    meta: {
      layout: "layout-blank",
    },
  },
  {
    name: "login",
    path: "/login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@index/views/login/login.vue"
      ),
    meta: {
      layout: "layout-full-screen",
    },
  },
  {
    name: "lk",
    path: "/lk",
    component: () =>
      import(/* webpackChunkName: "lk" */ "@index/views/lk/lk.vue"),
    meta: {
      layout: "layout-full-screen",
    },
    children: [
      {
        name: "lkprofile",
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "lk" */ "@index/views/lk/components/profile.vue"
          ),
        meta: {
          layout: "layout-full-screen",
        },
      },
      {
        name: "lkcontracts",
        path: "contracts",
        component: () =>
          import(
            /* webpackChunkName: "lk" */ "@index/views/lk/components/contracts/contracts.vue"
          ),
        meta: {
          layout: "layout-full-screen",
        },
      },
    ],
  },
  {
    name: "NotFound",
    path: "*",
    component: () => import("@index/views/not-found/not-found.vue"),
    meta: {
      layout: "layout-width-full",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
